import React, {Component, Fragment} from 'react';
import ListaTabla from './Includes/ListaTabla';
import ModalSolicitudAceptada from './Includes/ModalSolicitudAceptada';
import {SolicitudPagoService} from '../../../services/_Sis/SolicitudPagoService/SolicitudPagoService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {BotonActualizarLista, BotonExportarListaExcel, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import Paginacion from "../../Include/Paginacion/Paginacion";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import FiltrosSolicitudAceptada from "./Includes/FiltrosSolicitudAceptada";


class SolicitudAceptada extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		this.state = {
			listar_solicitud_pago: [],
			cat_proveedor: [],
			cat_banco: [],
			cat_area_proyecto: [],
			cat_tipo_pago: [],
			cat_usuario: [],
			
			filtro: {
				id_cat_estatus_solicitud_pago: 1
			},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			ids_expandido: ids_expandido || [],
		};
		
		this.Listar();
		this.ListUsuario();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	Listar = () => {
		SolicitudPagoService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_solicitud_pago: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({
				listar_solicitud_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListProveedor = () => {
		return new Promise((resolve, reject) => {
			CatService.ListProveedor().then((response) => {
				this.setState({
					cat_proveedor: response.data
				});
				resolve(response);
			});
		});
	};
	
	ListEtapaProyecto = (valor) => {
		return new Promise((resolve) => {
			CatService.ListEtapaProyecto(valor).then((response) => {
				this.setState({
					cat_etapa_proyecto: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		});
	}
	
	ListSubEtapaProyecto = (valor) => {
		return new Promise((resolve) => {
			CatService.ListSubEtapaProyecto(valor).then((response) => {
				this.setState({
					cat_subetapa_proyecto:response.data
				});
				resolve(response);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		});
	}
	
	ListClase = () => {
		return new Promise((resolve) => {
			CatService.ListClase().then((response) => {
				this.setState({
					cat_clase:response.data
				});
				resolve(response);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		});
	}
	
	ListSubClase = (valor) => {
		return new Promise((resolve) => {
			CatService.ListSubClase(valor).then((response) => {
				this.setState({
					cat_subclase:response.data
				});
				resolve(response);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		});
	}
	
	ListUsuario = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuario(3).then((response) => {
				this.setState({
					cat_usuario: response.data
				});
				resolve(response);
			});
		});
	};
	
	ListBanco = () => {
		return new Promise((resolve, reject) => {
			CatService.ListBanco().then((response) => {
				this.setState({
					cat_banco: response.data
				});
				resolve(response);
			});
		});
	};
	
	ListAreaProyecto = () => {
		return new Promise((resolve, reject) => {
			CatService.ListAreaProyecto().then((response) => {
				this.setState({
					cat_area_proyecto: response.data
				});
				resolve(response);
			});
		});
	};
	
	ListTipoPago = () => {
		return new Promise((resolve, reject) => {
			CatService.ListTipoPago().then((response) => {
				this.setState({
					cat_tipo_pago: response.data
				});
				resolve(response);
			});
		});
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	exportar = (item, archivo, auth) => {
		this.showSnackBars('error', 'En desarrollo');
		return
		let filtro = {
			id_cuenta_bancaria: item.id_cuenta_bancaria || '',
			id_usuario_apoderado: item.id_usuario_apoderado || '',
		};
		console.log('EXPORTAR::: ', item);
		HttpRequest.export('cuentasbancarias', ['cuentas_bancarias'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_solicitud_pago', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_solicitud_pago: item.id_solicitud_pago
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_solicitud_pago) !== Number(item.id_solicitud_pago)) {
					ids_expandido.push({
						id_solicitud_pago: ids_temp[i].id_solicitud_pago
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			console.log(variable && campo, variable , campo)
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Solicitudes Aprobadas'}
					/*botonPDF={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaPDF onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}*/
					botonEXCEL={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaExcel onClick={() => this.exportar({}, 'EXCEL', true)}/>
							) : ''}
						</Fragment>
					}
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				<FiltrosSolicitudAceptada
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
					ListBanco={this.ListBanco}
					ListAreaProyecto={this.ListAreaProyecto}
					lista_usuario={this.state.cat_usuario}
				/>
				
				<div className={'form margin-30-B'} style={{marginTop: '20px'}}>
					
					{this.state.listar_solicitud_pago.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_solicitud_pago}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
								SubTabla={this.SubTabla}
								ListUsuario={this.ListUsuario}
								ListEtapaProyecto={this.ListEtapaProyecto}
								ListSubEtapaProyecto={this.ListSubEtapaProyecto}
								ListClase={this.ListClase}
								ListSubClase={this.ListSubClase}
								ListProveedor={this.ListProveedor}
								ListTipoPago={this.ListTipoPago}
								ListBanco={this.ListBanco}
								ListAreaProyecto={this.ListAreaProyecto}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				{/*<ModalSolicitudAceptada
					tipo={'add'}
					item={{}}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
					ListUsuario={this.ListUsuario}
					ListProveedor={this.ListProveedor}
					ListBanco={this.ListBanco}
					ListAreaProyecto={this.ListAreaProyecto}
					ListTipoPago={this.ListTipoPago}
				/>*/}
			
			</div>
		);
	}
}

SolicitudAceptada.propTypes = {};

export default IntegrationNotistack(SolicitudAceptada);
