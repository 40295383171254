import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SolicitudPagoService} from "../../../../services/_Sis/SolicitudPagoService/SolicitudPagoService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	AccountBalance,
	HdrWeakOutlined,
	Person,
	Comment,
	FiberManualRecord,
	AccountTree,
	DeleteOutlined,
	AddOutlined, AttachMoney, CreditCard, CalendarTodayOutlined, LibraryBooks
} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Zoom from '@material-ui/core/Zoom/index';
import Button from "@material-ui/core/Button";
import {DropzoneArea} from "material-ui-dropzone";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import IconButton from "@material-ui/core/IconButton";


class ModalSolicitudPago extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_solicitud_pago: props.item.id_solicitud_pago || '',
			id_cat_estatus_solicitud_pago: '',
			fecha_pago: null,
			cat_estatus_solicitud_pago: [],
			comprobantes: [],
			comentarios: '',
			
			archivo_base64Tipo: '',
			archivo_base64: '',
			archivo: '',
			archivo_formato: '',
			
			activo: true,
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		let msg = `¿Deseas guardar el comprobante de la solicitud de pago?`;
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Aceptar') {
				if (this.state.files.length > 0) {
					for (let i = 0; i < this.state.files.length; i++) {
						this.darformato(this.state.files[0]).then(() => {
							
							SolicitudPagoService.Comprobante(this.state).then((response) => {
								//this.close();
								this.props.RefreshList();
								this.props.showSnackBars('success', response.mensaje);
							}).catch((error) => {
								this.props.showSnackBars('error', error.mensaje);
							});
							
						}).catch((error) => {
							this.props.showSnackBars('error', error.mensaje);
						});
					}
				} else {
					
					SolicitudPagoService.Comprobante(this.state).then((response) => {
						//this.close();
						this.props.RefreshList();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				}
				
			}
		});
	};
	
	
	add = () => {
		const {item} = this.props;
		
		this.setState({
			id_solicitud_pago: item.id_solicitud_pago || '',
			id_cat_estatus_solicitud_pago: item.id_cat_estatus_solicitud_pago || '',
			comentarios: '',
			comprobantes: item.comprobantes || [],
			archivo_base64Tipo: '',
			archivo_base64: '',
			archivo: '',
			archivo_formato: '',
			
			activo: true
		});
		
		this.open();
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
		
	};
	
	
	darformato = (file) => {
		return new Promise((resolve, reject) => {
			let formatos = ["text/xml", ".csv", "text/csv", "application/xlsx", "application/pdf", "application/xls", "application/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
			
			let fil = {files: [file]};
			FileBase64.Base64(fil, formatos, this.props.MegaByte || null, true).then((response) => {
				//this.props.onChange(response);
				this.handleChange({
					target: {
						name: 'archivo_base64',
						value: response.base64
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_base64Tipo',
						value: response.base64Tipo
					}
				});
				this.handleChange({
					target: {
						name: 'archivo',
						value: response.archivo
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_formato',
						value: response.formato
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_nombre',
						value: response.name
					}
				});
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject();
			});
		});
	}
	
	exportar = (link, tipo) => {
		let url = CONFIG.src + link;
		window.open(url, '', 'width=900, height=600');
	};
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el comprobante?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				SolicitudPagoService.EliminarComprobante(item.id_solicitud_pago_comprobante).then((response) => {
					this.close();
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_solicitud_pago}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Comprobante'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<RadioGroup
									row
									aria-label="position"
									name="id_cat_estatus_solicitud_pago"
									value={this.state.id_cat_estatus_solicitud_pago.toString()}
									onChange={(e) => {
										this.handleChange(e);
									}}>
									
									{this.state.cat_estatus_solicitud_pago && this.state.cat_estatus_solicitud_pago.map((value, index) => (
										<FormControlLabel
											key={index}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value={value.id_cat_estatus_solicitud_pago.toString()}
											label={value.estatus}
										/>
									))}
								</RadioGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<CalendarTodayOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											label="Fecha Pago"
											value={this.state.fecha_pago}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_pago');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Comment className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="comentarios"
										           onChange={this.handleChange}
										           label="Observaciones" autoComplete={'off'}
										           value={this.state.comentarios}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={0}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<DropzoneArea
											onChange={this.handleChangeDropzoneArea.bind(this)}
											//open={this.state.open}
											filesLimit={1}
											dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
											acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
											showPreviews={false}
											maxFileSize={5000000}
											showAlerts={false}
											showFileNames={true}
											showFileNamesInPreview={true}
										/>
									
									</Grid>
								</Grid>
							</Grid>
							
							{this.state.comprobantes.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<table className={'desing-acresco'}>
										<thead>
										<tr>
											<th align={"left"}>Fecha Pago</th>
											<th align={"left"}>Observación</th>
											<th align={"left"}>Acciones</th>
										</tr>
										</thead>
										<tbody>
										{this.state.comprobantes.map((item, index) => (
												<tr key={index}>
													<td align={"left"}>{DateFormat.getFechaText(item.fecha_pago)}</td>
													<td align={"left"}>{item.comentarios}</td>
													<td align={"left"}>
														<div className={'vertical-inline'}>
															<div className={'v-center'}>
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver" onClick={() => this.exportar(item.archivo)}>
																	<IconButton aria-label="Ver">
																		<LibraryBooks/>
																	</IconButton>
																</Tooltip>
															</div>
															<div className={'v-center'}>
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																	<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}>
																		<DeleteOutlined/>
																	</IconButton>
																</Tooltip>
															</div>
														</div>
													</td>
												</tr>
											)
										)}
										</tbody>
									</table>
								</Grid>
							) : null}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_solicitud_pago}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSolicitudPago.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalSolicitudPago;
