import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import Table from "@material-ui/core/Table";
import {FieldsJs} from "../../../../settings/General/General";
import SubEtapaProyectoService from "../../../../services/_Cat/SubEtapaProyectoService/SubEtapaProyectoService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {BotonAccionAbrirModal, BotonAccionFooterModal} from "../../../Include/MiniComponents/GlobalComponent";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import EtapaProyectoService from "../../../../services/_Cat/EtapaProyectoService/EtapaProyectoService";
import ModalCuentaBancaria from "../../CuentaBancaria/Includes/ModalCuentaBancaria";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {HdrWeakOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

class ModalCuentas extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cat_etapa_proyecto: '',
			id_cat_subetapa_proyecto: (props.item || '').id_cat_subetapa_proyecto,
			id_cat_area_proyecto: '',
			etapa: '',
			subetapa: '',
			descripcion: '',
			cat_area_proyecto: [],
			cat_etapa_proyecto: [],
			listar_cuenta_bancaria: [],
			subetapas: (props.item || []).subetapas || [],
			activo: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_cat_etapa_proyecto > 0 && this.props.tipo !== 'add') {
			SubEtapaProyectoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.closeModal();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			SubEtapaProyectoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		const {item} = this.props;
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || '',
			});
		});
		
		console.log(this.props);
		this.setState({
			id_cat_subetapa_proyecto: '',
			item: item || '',
			etapa: item.etapa || '',
			subetapa: item.subetapa || '',
			subetapas: item.subetapas || [],
			descripcion: '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || '',
			});
		});
		if (FieldsJs.Field(item.id_cat_etapa_proyecto)) {
			this.props.ListEtapaProyecto(item.id_cat_area_proyecto).then((response) => {
				this.setState({
					cat_etapa_proyecto: response.data,
					id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
				});
			});
		} else {
			this.setState({
				id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
			});
		}
		
		this.setState({
			etapa: item.etapa || '',
			subetapa: item.subetapa || '',
			subetapas: item.subetapas || [],
			descripcion: item.descripcion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || ''
			});
		});
		
		if (FieldsJs.Field(item.id_cat_area_proyecto)) {
			this.props.ListEtapaProyecto(item.id_cat_area_proyecto).then((response) => {
				this.setState({
					cat_etapa_proyecto: response.data,
					id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
				});
			});
		} else {
			this.setState({
				id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
			});
		}
		
		this.setState({
			id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
			etapa: item.etapa || '',
			subetapa: item.subetapa || '',
			subetapas: item.subetapas || [],
			descripcion: item.descripcion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	agregarPartida = () => {
		try {
			
			if (!this.state.descripcion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Sub Etapa.'
				})
			}
			
			let partidas = FieldsJs.Copy(this.state.descripcions);
			
			partidas.push(this.state.descripcion || '');
			
			this.setState({
				descripcion: '',
				descripcions: partidas,
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	
	eliminarPartida = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar la sub-etapa?').then((r) => {
				if (r.button === 'Aceptar') {
					let partidas = FieldsJs.Copy(this.state.descripcions);
					
					partidas.splice(key, 1);
					
					this.setState({
						descripcions: partidas
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el sub-etapa ${item.subetapa}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				EtapaProyectoService.Eliminar(item.id_cat_subetapa_proyecto).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	etapaProyecto = (id_cat_area_proyecto) => {
		this.props.ListEtapaProyecto(id_cat_area_proyecto).then((response) => {
			this.setState({
				cat_etapa_proyecto: response.data
			});
		});
	}
	
	render() {
		
		const {lista} = this.props;
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_subetapa_proyecto}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container direction="row" justify="flex-end" alignItems="rigth" spacing={2}>
							<Grid item className={'text-rigth'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography variant={'h5'} className={'text-rigth'}>
									{"Cuentas Bancarías"}
								</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container alignItems="center" spacing={1}>
							<Grid item className={'text-rigth'} xs={12} sm={12} md={12} lg={12} xl={12}>
								{lista.length > 0 ? (
									<Fragment>
										
										<Table className={'desing-acresco'}>
											<TableHead>
												<TableRow>
													<TableCell component="th" align={'left'}>Id</TableCell>
													<TableCell component="th" align={'left'}>Beneficiario</TableCell>
													<TableCell component="th" align={'left'}>Quien recibe</TableCell>
													<TableCell component="th" align={'left'}>Nombre Comercial</TableCell>
													<TableCell component="th" align={'left'}>Banco</TableCell>
													<TableCell component="th" align={'left'}>No. Cuenta</TableCell>
													<TableCell component="th" align={'left'}>No. Tarjeta</TableCell>
													<TableCell component="th" align={'left'}>Clabe</TableCell>
													<TableCell component="th" align={'left'}>Area / Proyecto</TableCell>
													<TableCell component="th" align={'center'}>Acciones</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{lista.map((item, key) => (
													<TableRow key={key}>
														<TableCell align={'center'}>{item.id_cuenta_bancaria}</TableCell>
														<TableCell align={'left'}>{item.nombre_razon_social}</TableCell>
														<TableCell align={'left'}>{item.nombre_recibe_pago}</TableCell>
														<TableCell align={'left'}>{item.nombre_comercial}</TableCell>
														<TableCell align={'left'}>{item.banco}</TableCell>
														<TableCell align={'left'}>{item.numero_cuenta}</TableCell>
														<TableCell align={'left'}>{item.numero_tarjeta}</TableCell>
														<TableCell align={'left'}>{item.clabe}</TableCell>
														<TableCell align={'left'}>{item.area_proyecto}</TableCell>
														<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
															<div className={'w-auto vertical-inline'}>
																<div className={'v-center'}>
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																		<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}>
																			<DeleteOutlined/>
																		</IconButton>
																	</Tooltip>
																</div>
																<div className={'v-center'}>
																	<ModalCuentaBancaria
																		tipo={'edit'}
																		item={item}
																		RefreshList={this.props.RefreshList}
																		showSnackBars={this.props.showSnackBars}
																		ListProveedor={this.props.ListProveedor}
																		ListBanco={this.props.ListBanco}
																		ListAreaProyecto={this.props.ListAreaProyecto}
																	/>
																</div>
																<div className={'v-center'}>
																	<ModalCuentaBancaria
																		tipo={'view'}
																		item={item}
																		RefreshList={this.props.RefreshList}
																		showSnackBars={this.props.showSnackBars}
																		ListProveedor={this.props.ListProveedor}
																		ListBanco={this.props.ListBanco}
																		ListAreaProyecto={this.props.ListAreaProyecto}
																	/>
																</div>
															</div>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
										<br/>
									</Fragment>
								) : (
									<VistaVacia
										numero={0}
										mensaje={'No se encontraron datos.'}
									/>
								)}
							
							</Grid>
							
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
							<Grid item className={'text-rigth'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Button onClick={this.close} color="primary">
									{'Cerrar'}
								</Button>
								
								<ModalCuentaBancaria
									tipo={'add'}
									item={this.state.item}
									RefreshList={this.props.RefreshList}
									showSnackBars={this.props.showSnackBars}
									ListProveedor={this.props.ListProveedor}
									ListBanco={this.props.ListBanco}
									ListAreaProyecto={this.props.ListAreaProyecto}
								/>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCuentas.propTypes = {
	lista: PropTypes.array.isRequired,
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default ModalCuentas;
