import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const SolicitudPagoService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				no_recibo: filtro.no_recibo || null,
				usuario: filtro.usuario || null,
				id_cat_area_proyecto: filtro.id_cat_area_proyecto || null,
				id_cat_estatus_solicitud_pago: filtro.id_cat_estatus_solicitud_pago || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Solicitud_Pago_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		
		let params = {
			id_solicitud_pago: null,
			no_recibo: form.no_recibo || '',
			id_usuario: form.id_usuario || '',
			id_cat_clasificacion_pago: form.id_cat_clasificacion_pago || '',
			id_proveedor: form.id_proveedor || '',
			beneficiario: form.beneficiario || '',
			banco: form.banco || '',
			numero_tarjeta: form.numero_tarjeta || '',
			id_cat_area_proyecto: form.id_cat_area_proyecto || '',
			id_cat_etapa_proyecto: form.id_cat_etapa_proyecto || '',
			id_cat_subetapa_proyecto: form.id_cat_subetapa_proyecto || '',
			id_cat_clase: form.id_cat_clase || '',
			id_cat_subclase: form.id_cat_subclase || '',
			id_cat_banco: form.id_cat_banco || '',
			observaciones: form.observaciones || '',
			partidas: form.partidas || [],
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Solicitud_Pago_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_solicitud_pago: form.id_solicitud_pago,
			no_recibo: form.no_recibo || '',
			id_usuario: form.id_usuario || '',
			id_cat_clasificacion_pago: form.id_cat_clasificacion_pago || '',
			id_proveedor: form.id_proveedor || '',
			beneficiario: form.beneficiario || '',
			banco: form.banco || '',
			numero_tarjeta: form.numero_tarjeta || '',
			id_cat_area_proyecto: form.id_cat_area_proyecto || '',
			id_cat_etapa_proyecto: form.id_cat_etapa_proyecto || '',
			id_cat_subetapa_proyecto: form.id_cat_subetapa_proyecto || '',
			id_cat_clase: form.id_cat_clase || '',
			id_cat_subclase: form.id_cat_subclase || '',
			id_cat_banco: form.id_cat_banco || '',
			observaciones: form.observaciones || '',
			partidas: form.partidas || [],
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Solicitud_Pago_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_solicitud_pago) => {
		let params = {
			id_solicitud_pago: id_solicitud_pago
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Solicitud_Pago_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	NoRecibo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Solicitud_Pago_NoRecibo', {}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EstatusAgregar: (form) => {
		let params = {
			id_solicitud_pago: form.id_solicitud_pago,
			id_cat_estatus_solicitud_pago: form.id_cat_estatus_solicitud_pago,
			comentarios: form.comentarios,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Solicitud_Pago_Estatus', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Comprobante: (form) => {
		let fecha_pago = form.fecha_pago ? DateFormat.FormatSql(form.fecha_pago) : '';
		let params = {
			id_solicitud_pago: form.id_solicitud_pago,
			id_cat_estatus_solicitud_pago: form.id_cat_estatus_solicitud_pago,
			fecha_pago: fecha_pago,
			comentarios: form.comentarios,
			archivo: form.archivo,
			archivo_formato: form.archivo_formato,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Solicitud_Pago_Comprobante', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EliminarComprobante: (id_solicitud_pago_comprobante) => {
		let params = {
			id_solicitud_pago_comprobante: id_solicitud_pago_comprobante
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Solicitud_Pago_Eliminar_Comprobante', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
